import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import {
  AboutSection,
  FAQ,
  Footer,
  Header,
  MetaHead,
  Presale,
  Roadmap,
  Services,
  ShowCase,
  VideoPreviewSection,
} from '@/components/atomic';

export const Index: React.FC = () => {
  return (
    <>
      <MetaHead />
      <Header />
      <ShowCase />
      <Presale />
      <AboutSection />
      <VideoPreviewSection />
      <Services />
      <Roadmap />
      <section className="mx-5 lg:mx-20">
        <FAQ />
        <Footer />
      </section>
    </>
  );
};

export default Index;

export async function getStaticProps({ locale }: { locale: string }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
}
